import { useEffect } from "react";
import Separator from "@/pages/separator";
import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.webp";
import logoDark from "../../public/images/logo/logo-dark.webp";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";
import SocialFooter from "./FooterProps/SocialFooter";

const Footer = () => {

  useEffect(() => {
    const urlHash = window.location.hash;
      if (urlHash.length) {
        const element = document.getElementById(urlHash.substring(1));
        if (element) {
          element.scrollIntoView();
        }
      }
  });
  
  return (
    <>
      <footer className="rainbow-footer footer-style-default footer-style-3 position-relative">
        <Separator top={true} />
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center mb--30">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="rainbow-footer-widget text-center">
                  <div className="logo">
                    <Link href="/">
                      <Image
                        className="logo-light"
                        src={logo}
                        width={201}
                        height={35}
                        alt="ETIPort.AI Logo"
                      />
                      <Image
                        className="logo-dark"
                        src={logoDark}
                        width={201}
                        height={35}
                        alt="ETIPort.AI Logo"
                      />
                    </Link>
                  </div>
                  <p className="b1 text-center mt--20 mb--0">
                    Boost your profits by receiving real-time buy/sell alerts with our AI-based algorithm.
                  </p>
                </div>
              </div>
            </div>
            <div className="separator-animated animated-true mt--50 mb--50"></div>
            {FooterData &&
              FooterData.footer.map((data, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="rainbow-footer-widget">
                      <h4 className="title">{data.title}</h4>
                      <div className="inner">
                        <h6 className="subtitle">{data.desc}</h6>
                        <form className="newsletter-form" action="#">
                          <div className="form-group">
                            {/* <input
                              type="email"
                              placeholder="Enter Your Email Here"
                            /> */}
                            <div className="header-btn">
                              <Link
                                className={`btn-default bg-solid-primary`}
                                href="/earlyregister"
                              >
                                Register & Earn 50% Discount
                                <i className="feather-arrow-right"></i>
                              </Link>
                            </div>
                            {/* <button
                              className="btn-default bg-solid-primary"
                              type="submit" 
                            >
                              Register & Earn 50% Discount
                              <i className="feather-arrow-right"></i>
                            </button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <SingleFooter data={data.company} />
                  <SingleFooter data={data.products} />
                  {/* <SingleFooter data={data.algorithms} /> */}
                  <SocialFooter data={data.social} />
                </div>
              ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
