import Link from "next/link";
import React from "react";
import { SiInstagram, SiX, SiLinkedin, SiTiktok, SiYoutube } from "react-icons/si";

const SocialFooter = ({ data }) => {
  return (
    <>
      <div className="col-lg-2 col-md-6 col-sm-6 col-12" >
        <div className="rainbow-footer-widget">
          <div className="widget-menu-bottom">
            <h4 className="title">Follow Us</h4>
            <div className="inner">
              <ul className="footer-link link-hover">
                  <li>
                    <Link href="https://www.instagram.com/etiportai" target="_blank" >
                      <SiInstagram /> - Instagram</Link>
                  </li>
                  <li>
                    <Link href="https://twitter.com/ETIPortAI" target="_blank" >
                      <SiX /> - X</Link>
                  </li>
                  <li>
                    <Link href="https://www.linkedin.com/company/etiportai" target="_blank" >
                      <SiLinkedin /> - LinkedIn</Link>
                  </li>
                  <li>
                    <Link href="https://www.tiktok.com/@etiportai" target="_blank" >
                      <SiTiktok /> - TikTok</Link>
                  </li>
                  <li>
                    <Link href="https://www.youtube.com/@etiport-ai" target="_blank" >
                      <SiYoutube /> - Youtube</Link>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialFooter;
